<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <img src="http://static.jzybyy.com/rule/pc_eggs.png"/>

    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'

export default {
  name: "index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      list:[]
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
  },
  methods:{

  }
}
</script>

<style scoped>
</style>
